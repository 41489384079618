import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { loginUser, logUserOut } from "../../module/auth/store/thunk";

type IPath = {
  link: string;
  name: string;
  id: string;
  title: string;
  icon?: any;
  primary?: string;
};
type AppStateType = {
  openedTabs: IPath[];
};

const initialState: AppStateType = {
  openedTabs: [],
};

const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetData: (state) => {
      const keys = Object.keys(state) as Array<keyof typeof state>;
      keys.forEach((key) => {
        switch (key) {
          default:
            state[key] = initialState[key];
            break;
        }
      });
    },
    addNewTab: (state, { payload }: PayloadAction<IPath>) => {
      if (state.openedTabs.find((item) => item.id === payload.id)) return;
      state.openedTabs.push(payload);
    },
    removeTab: (state, { payload }: PayloadAction<string>) => {
      const otherTabs = state.openedTabs.filter((tab) => tab.id !== payload);
      state.openedTabs = [...otherTabs];
    },
    updateTabLink: (
      state,
      { payload }: PayloadAction<{ id: string; link: string }>
    ) => {
      if (!payload?.id || !payload?.link) return;
      state.openedTabs = state.openedTabs.map((item) =>
        item.id === payload.id ? { ...item, link: payload.link } : item
      );
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      isAnyOf(logUserOut.fulfilled, loginUser.fulfilled),
      (state) => {
        const keys = Object.keys(state) as Array<keyof typeof state>;
        keys.forEach((key) => {
          switch (key) {
            default:
              state[key] = initialState[key];
              break;
          }
        });
      }
    );
  },
});

export const appStateActions = appStateSlice.actions;

export default appStateSlice.reducer;
